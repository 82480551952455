import {
	AfterContentChecked,
	AfterViewChecked,
	AfterViewInit,
	Component, ElementRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges, ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EducationService, ModalService} from "@services";
import {environment} from "@environments";
import {AbstractControl} from '@angular/forms';


@Component({
	selector: 'app-about-course',
	templateUrl: './about-course.component.html',
	styleUrls: ['./about-course.component.scss'],
})

export class AboutCourseComponent implements OnInit, AfterContentChecked {
	protected readonly environment = environment;
	@Input() idDirection: number;
	data = {
		id: null,
		name: '',
		description: '',
		detail_description: '',
		intensive_description: '',
		suitable_description: '',
		image: '',
		host_name: '',
		host_info: ''
	};

	fileSelected: boolean = false;
	fileSelectedPortfolio: boolean = false;
	formData: FormData = new FormData();
	file: File | null = null;
	filePortfolio: File | null = null;
	errorMessage = {
		name: [],
		email: [],
		phone: [],
		message: [],
		portfolio_link: []
	};

	text: string = '';
	characterCount: number = 0;

	public form = new FormGroup({
		'name': new FormControl(),
		'email': new FormControl(),
		'phone': new FormControl(),
		'message': new FormControl(),
		'file': new FormControl(),
		'filePortfolio': new FormControl(),
		'portfolio': new FormControl(),
	});

	constructor(private modalService: ModalService,
				private api: EducationService,
				private fb: FormBuilder) {
	}

	getDataCourse() {
		return this.modalService.getDataCourse();
	}

	ngOnInit() {
		this.form = this.fb.group({
			full_name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]],
			phone: ['', Validators.required],
			telegram: ['', Validators.required],
			file: [null, Validators.required],
			direction_id: [''],
			internship_id: [''],
			personalDataPolicy: [false, Validators.requiredTrue],
			personalData: [false, Validators.requiredTrue],
			portfolio_link: [null],
			portfolio: [null],
		}, {validator: this.atLeastOneFieldValidator.bind(this)});
	}

	ngAfterContentChecked() {
		this.data = this.getDataCourse();
	}


	onChange(e: any) {
		e.target.files.length > 0 ? this.fileSelected = true : this.fileSelected = false;
		if (e.target.files.length > 0) {
			this.file = e.target.files[0];
		}
	}

	onChangePortfolio(e: any) {
		e.target.files.length > 0 ? this.fileSelectedPortfolio = true : this.fileSelectedPortfolio = false;
		if (e.target.files.length > 0) {
			this.filePortfolio = e.target.files[0];
		}
	}


	onSubmit() {
		let form = this.form.value;
		this.formData.append('full_name', form.full_name);
		this.formData.append('email', form.email);
		this.formData.append('phone', '+' + form.phone);
		this.formData.append('telegram', form.telegram);
		this.formData.append('resume', this.file, this.file.name);
		this.formData.append('direction_id', `${this.idDirection}`);
		this.formData.append('internship_id', this.data.id);
		if (form.portfolio_link) {
			this.formData.append('portfolio_link', form.portfolio_link);
		} else {
			this.formData.delete('portfolio_link');
		}
		if (this.filePortfolio) {
			this.formData.append('portfolio', this.filePortfolio, this.filePortfolio.name);
		} else {
			this.formData.delete('portfolio');
		}

		this.api.signUpInternship(this.formData)
			.subscribe((res) => {
				this.form.reset();
				this.modalService.close('about-course');
				this.fileSelected = false;
				this.fileSelectedPortfolio = false;
				this.file = null;
				this.filePortfolio = null;
				res.duplicateRequest ? this.modalService.open('take-test') : this.modalService.open('successful-sending');
			}, error => {
				if (error.error.errors) {
					for (const key of Object.keys(error.error.errors)) {
						this.errorMessage[`${key}`] = `${error.error.errors[key]}`
					}
				}
			});
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	onInputChange(el: string) {
		this.errorMessage[el] = [];
	}

	atLeastOneFieldValidator(control: AbstractControl, state?) {
		const textValue = control.get('portfolio_link').value;
		const fileValue = control.get('portfolio').value;

		if(this.idDirection === 1){
			if (!textValue && !fileValue) {
				return {atLeastOneFieldRequired: true};
			}
			return null;
		}else{
			return true;
		}
	}

	countCharacters() {
		this.characterCount = this.text.length;
	}
}
